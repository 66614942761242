function getAllFeatures() {
    let features;
    try {
        features = localStorage.getItem("features") ? JSON.parse(localStorage.getItem("features")) : {};
    }
    catch (e) {
        features = {};
    }
    return features;
}
export function getFeatureByName(name) {
    const features = getAllFeatures();
    if (undefined !== typeof features[name]) {
        return Object.assign({ enabled: false }, features[name]);
    }
    return null;
}
