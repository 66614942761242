import React from "react";
import "./ContributionButton.css";
export default function ({ button_icon = false, button_text = "Je soutiens La Presse", contribution_amount = null, contribution_type = null, suptitle = null, suptitle_bold = false, utm_campaign = "jesoutiens", utm_medium = "boutonSection", utm_source = "lpca", utm_content = "", }) {
    const url = new URL(window.location.origin);
    url.pathname = "/contribution";
    url.searchParams.append("utm_campaign", utm_campaign);
    url.searchParams.append("utm_source", utm_source);
    url.searchParams.append("utm_medium", utm_medium);
    url.searchParams.append("utm_content", utm_content);
    if (contribution_amount !== null) {
        url.searchParams.append("amount", contribution_amount.toString());
    }
    if (contribution_type !== null) {
        url.searchParams.append("contributionType", contribution_type);
    }
    const classes = ["btnContribution"];
    if (button_icon) {
        classes.push("btnContribution--bold");
    }
    const button = React.createElement("a", { "data-target-type": "contribution", className: `${classes.join(" ")}`, href: url.toString(), rel: "noopener", target: "_blank" },
        React.createElement(React.Fragment, null,
            !!button_icon && React.createElement("div", { className: "btnContribution__heartContainer" },
                React.createElement("img", { alt: "heart", className: "btnContribution__heartImg" })),
            React.createElement("div", { className: !!button_icon ? "btnContribution__content" : "" }, button_text)));
    if (!suptitle) {
        return button;
    }
    return React.createElement("div", { className: "donationContext" },
        React.createElement("div", { className: !!suptitle_bold ? "donationContext--bold" : "" }, suptitle),
        button);
}
